import "./footer.css";
import logo from '../../assets/images/logo01-1@2x.png';
import F1 from '../../assets/images/f-e-1.png';
import F2 from '../../assets/images/f-e-2.png';
import facebook from '../../assets/images/facebook.png'
import twitter from '../../assets/images/twitter.png'
import whatsapp from '../../assets/images/whatsapp.png'
import youtube from '../../assets/images/youtube.png'
import instagram from '../../assets/images/instagram.png'

function Footer() {
  return (
    <div className="section-footer">
      <footer className="footer-bs">
        <div className="row">
          <div className="col-md-3 footer-brand animated fadeInLeft">
            <img src={F2} className="footer-cir" alt="Eclipse 1" />
            <img
              className="logo-footer"
              src={logo}
              alt="Footer logo"
            ></img>
            <p className="mt-2">
              At ForunPoint, our vision is to be among the top online fresh food
              delivery service. The mission is to have a
              great food selection.
            </p>
          </div>
          <div className="col-md-2 footer-nav animated fadeInUp">
            <h4 className="footer-heading">Services</h4>
            <ul className="pages">
              <li>
                <a href="#food-section">Restaurant</a>
              </li>
              <li>
                <a href="#food-section">Gifts & Flowers</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              <li>
                <a href="/terms">Terms & Conditions</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/forunpoint-solution">ForunPoint's Solution</a>
              </li>
              <li>
                <a href="/message-ceo">Message from CEO</a>
              </li>
            </ul>
          </div>

          <div className="col-md-2 footer-nav animated fadeInUp">
            <h4 className="footer-heading">Reach Us</h4>
            <ul className="pages">
              <li>
                <a href="/join-us">Join as Restaurant</a>
              </li>
              <li>
                <a href="/driver">Join as Rider</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 footer-ns animated fadeInRight newsletter">
            <h4 className="footer-heading">Join our Newsletter</h4>
            <p>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control border no-outline"
                  placeholder="Email"
                  id="newsletter-input"
                />
                <button className="subscribe-btn" type="button">
                  Subscribe
                </button>
              </div>
              <p className="newsletter-text">*  Will send you weekly updates.</p>
              <p className="social-media">Social Media</p>
              <div className="social-media-icons">
                <a href="https://www.facebook.com/forunpoint.pk/" target="_blank"><img src={facebook} alt="facebook-icon" /></a>
                <a href="https://twitter.com/Forunpointpk" target="_blank"><img src={twitter} alt="twitter-icon" /></a>
                <a href="tel:+923244446265"><img src={whatsapp} alt="Whatsapp" /></a>
                <a href="https://www.youtube.com/@forunpointpakistan" target="_blank"><img src={youtube} alt="Youtube" /></a>
                <a href="https://www.instagram.com/forunpoint.pk/" target="_blank"><img src={instagram} alt="Instagram" /></a>
              </div>
              <img src={F1} className="footer-cir-2" alt="Eclipse 1" />
            </p>
          </div>
          <hr className="footer-hr" />
          <div className="footer-bottom">
            <p className="footer-text">© 2024 ForunPoint. All Rights Reserved.</p>
          </div>
          <div style={{ textAlign: 'right' }}>
            <p style={{ opacity: '0.4', fontSize: '12px', marginRight:'20px', marginTop:'20px' }}>v1.0.53</p>
          </div>


        </div>
      </footer>

    </div>
  );
}

export default Footer;
